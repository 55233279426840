import React, { useEffect, useRef } from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

const windowGlobal = typeof window !== 'undefined' && window;

function Contact() {
  const contactFormEmbedContainer = useRef();

  useEffect(() => {
    // const contactFormEmbedContainer = '.contact-form-embed';

    if (windowGlobal && contactFormEmbedContainer.current) {
      windowGlobal?.hbspt?.forms?.create({
        region: 'eu1',
        portalId: '26319006',
        formId: 'c757c20f-9405-41fc-8240-797921a85eea',
        version: 'V2_PRERELEASE',
        target: '.contact-form-embed',
      });
    }
  }, []);
  return (
    <Layout>
      <SEO title="Contact us" />
      <section className="inner-hero bg-lightgreen !mb-0">
        <div className="container">
          <div className="inner-hero-content text-center">
            <h1 className="text-primary font-medium mb-2">Contact Us</h1>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="col col-7">
              <div className="p-3 bg-lightgreen rounded-lg mb-5 md:mb-0">
                <h2 className="heading-small text-primary mb-1">
                  Have Questions?
                </h2>
                <p className="mb-3">
                  Please feel free to reach out to us using the below form.
                </p>

                <div
                  className="contact-form-embed"
                  ref={contactFormEmbedContainer}></div>
              </div>
            </div>

            <div className="col col-4 offset-1">
              {[
                {
                  id: 0,
                  icon: '/img/phone.png',
                  title: 'Call Us',
                  description: '88 0909 4343',
                  url: 'tel:88 0909 4343',
                },
                {
                  id: 1,
                  icon: '/img/email.png',
                  title: 'Email Us',
                  description: 'info.effipac@gmail.com',
                  url: 'mailto:info.effipac@gmail.com',
                },
              ].map(({ id, icon, title, description, url }) => (
                <a
                  href={url}
                  className="feature-single feature-text py-2 md:py-4 text-center bg-white block rounded-lg mt-5 mb-6">
                  <div className="feature-icon w-[88px] mb-2 mx-auto mt-[-60px] md:mt-[-80px] rounded-full border-lightyellow border-8 bg-lightyellow">
                    <img src={icon} alt={title} />
                  </div>
                  <h3 className="heading-smallest w-full text-primary mb-2 ">
                    {title}
                  </h3>

                  <p className="max-w-[350px] mx-auto text-18 font-medium mb-0">
                    {description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Contact;
